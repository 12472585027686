import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import { TakerMarket } from '@/data/useTakerMarkets';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';

export interface RuleExcludedInvoicesAlertProps {
  takerMarkets: TakerMarket[];
  excludedAmount: number;
  currency?: string;
  isPreferred?: boolean;
}

const RuleExcludedInvoicesAlert = ({
  takerMarkets,
  currency,
  excludedAmount,
  isPreferred = false,
}: RuleExcludedInvoicesAlertProps) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const selectedCurrency = useSelectedCurrency();
  const navigate = useNavigate();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions(takerMarkets);

  if (takerMarkets.length === 0 || excludedAmount === 0) {
    return null;
  }

  const description = (
    <div>
      <span>
        {t('recurringRules.excludedAmountAlert', {
          amount: asCurrency(excludedAmount, currency ?? selectedCurrency),
        })}
      </span>
      {isPreferred && <span> {t('recurringRules.preferredOfferLockedDescription')}</span>}
    </div>
  );

  return (
    <Alert
      description={description}
      full
      type="info"
      variant="outlined"
      {...(!isPreferred &&
        canEditRecurringRules && {
          action: {
            onClick: () => navigate('/supplier/recurring-rules'),
            text: t('recurringRules.manageRules'),
          },
        })}
    />
  );
};

export default RuleExcludedInvoicesAlert;
