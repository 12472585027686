import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import download from '@/assets/download.svg?url';
import { useReporting } from '@/reporting';
import { useToken } from '@/utils/token';

export interface CreditNoteModalProps {
  open: boolean;
  onClose: () => void;
  takerId: number;
  marketId: string;
  creditNoteId?: string | null;
}

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

export default function CreditNoteModal({ open, onClose, takerId, marketId, creditNoteId }: CreditNoteModalProps) {
  const { t } = useTranslation();
  const { token } = useToken();
  const { track } = useReporting();

  const downloadUrl = `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/retrieve-award-file/${creditNoteId}?token=${token}`;

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInfo>{t('core.authenticatedDownload.title')}</ModalTitleInfo>
      <ModalContent center>
        <img src={download} alt="download" className="h-16 w-16" />
        <p className="text-center text-base">{t('core.authenticatedDownload.description')}</p>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={onClose}>
          {t('core.cancel')}
        </Button>
        <a
          className="shrink"
          href={downloadUrl}
          onClick={() => {
            track('history-credit-note-request::submitted');
            onClose();
          }}
          target="_self"
        >
          <Button variant="primary">{t('core.download')}</Button>
        </a>
      </ModalActions>
    </Modal>
  );
}
