import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import useRecurringRules from '@/data/useRecurringRules';
import useTakerMarkets from '@/data/useTakerMarkets';
import NoActiveRules from '@/features/recurringRules/NoActiveRules';
import RecurringRulesForm, {
  RecurringRuleFormValues,
  RecurringRulesFormProps,
} from '@/features/recurringRules/RecurringRulesForm';
import RulesTable from '@/features/recurringRules/RulesTable';
import SuggestedRules from '@/features/recurringRules/SuggestedRules';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';

const RecurringRulesManagerComponent = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const recurringRules = useRecurringRules();
  const { data: takerMarkets = [] } = useTakerMarkets();
  const [recurringRulesFormOpen, setRecurringRulesFormOpen] = useState<boolean>(false);
  const [ruleModalProps, setRuleModalProps] = useState<Pick<RecurringRulesFormProps, 'defaultValues' | 'mode'> | null>(
    null
  );
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);

  const showRecurringRulesModal = (defaultValues?: Partial<RecurringRuleFormValues>) => {
    setRuleModalProps({
      defaultValues,
      ...((defaultValues?.criteria?.length ?? 0) > 0 && { mode: 'edit' }),
    });
    setRecurringRulesFormOpen(true);
  };

  const onNewRuleClick = () => {
    showRecurringRulesModal();
    track('recurring-rules::create');
  };

  const hasActiveRules = recurringRules.length > 0;

  return (
    <>
      <RecurringRulesForm
        onClose={() => setRecurringRulesFormOpen(false)}
        open={recurringRulesFormOpen}
        takerMarkets={takerMarkets}
        {...ruleModalProps}
      />
      <div className="flex flex-col gap-10">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <h1 className="m-0 pb-4 text-4xl font-medium sm:pb-0">{t('recurringRules.title')}</h1>
          <Button className="w-full sm:w-fit" onClick={onNewRuleClick} variant="cta" disabled={!canEditRecurringRules}>
            {t('recurringRules.createRecurringRule')}
          </Button>
        </div>
        {!hasActiveRules && <NoActiveRules showRecurringRulesModal={showRecurringRulesModal} />}
        {hasActiveRules && <RulesTable />}
        <SuggestedRules showRecurringRulesModal={showRecurringRulesModal} />
      </div>
    </>
  );
};

const LoadingSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="m-0 pb-4 text-4xl font-medium sm:pb-0">{t('recurringRules.title')}</h1>
      <div className="mt-16 space-y-4">
        <Skeleton height="h-80" />
        <div className="grid gap-10 pt-10 md:grid-cols-2 lg:gap-16">
          <Skeleton height="h-52" />
          <Skeleton height="h-52" />
        </div>
      </div>
    </>
  );
};

const RecurringRulesManager = () => (
  <QueryBoundaries LoadingComponent={LoadingSkeleton}>
    <RecurringRulesManagerComponent />
  </QueryBoundaries>
);

export default RecurringRulesManager;
