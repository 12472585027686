import { useMutation } from '@tanstack/react-query';
import useRefetchAndSubscribeForRules from '@/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import apiClient from '@/lib/apiClient';

export interface RecurringRuleArgument {
  makerOrganizationUuid: string;
  takersMarkets: {
    marketId: number;
    marketUuid: string;
    takerId: number;
    takerUuid: string;
  }[];
  gteDpe?: number;
  lteDpe?: number;
  gtDueDate?: string;
  ltDueDate?: string;
  gteInvoiceAmount?: number;
  lteInvoiceAmount?: number;
  excludedVoucherIds?: string[];
}

const useCreateRecurringRule = () => {
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['createRecurringRule'],
    mutationFn: (rule: RecurringRuleArgument) => {
      const { makerOrganizationUuid, ...restRuleArgs } = rule;
      const ruleArgs = {
        ...restRuleArgs,
        takersMarkets: restRuleArgs.takersMarkets.map((takerMarket) => ({
          marketId: takerMarket.marketId,
          takerId: takerMarket.takerId,
        })),
      };

      return apiClient
        .post(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules`, {
          json: ruleArgs,
        })
        .json();
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid, takersMarkets } = variables;
      refetchAndSubscribeRules({ makerOrganizationUuid, takerMarkets: takersMarkets });
    },
  });
};

export default useCreateRecurringRule;
