import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

/**
 * NOTE: We are keeping this around, even in a post shim world, to support "Exclude Future Invoices" via node api.
 * The reason is because sending the "exclude future invoices" setting in node does additional work,
 * like making updates to the invoice table directly and excluding maker excluded, adjusted, etc invoices.
 */
export interface UpdateOfferVariables {
  marketId: string;
  marketUuid: string;
  offerConfig: OfferConfig;
  takerId: number;
}

const useUpdateOffer = () => {
  const queryClient = useQueryClient();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['updateOffer'],
    mutationFn: ({ marketId, marketUuid, offerConfig, takerId }: UpdateOfferVariables) =>
      apiClient
        .put(`api/c2fo/taker/${takerId}/market/${marketId}/configuration/${offerConfig.id}`, {
          json: {
            id: offerConfig.id,
            isDiscountBidding: offerConfig.isDiscountBidding,
            isEnabled: offerConfig.isEnabled,
            maxApr: offerConfig.maxApr,
            maxDiscount: offerConfig.maxDiscount,
            divisionId: takerId,
            marketId,
            marketUuid,
            takerId,
            // if participation is turned off, also turn off exclude future invoices
            exclusionSettings: {
              ...offerConfig.exclusionSettings,
              excludeNewInvoices: !offerConfig.isEnabled
                ? false
                : offerConfig.exclusionSettings?.excludeNewInvoices ?? false,
            },
          },
        })
        .json<OfferConfig>(),
    onSuccess: async (data, variables) => {
      // update the cache with the returned data
      queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
        (prevTakerMarkets ?? []).map((tm) => ({
          ...(tm.marketId === variables.marketId && tm.takerDivisionId === variables.takerId
            ? {
                ...tm,
                offerConfig: {
                  ...tm.offerConfig,
                  id: data.id,
                  divisionId: data.divisionId,
                  exclusionSettings: data.exclusionSettings,
                  isDiscountBidding: data.isDiscountBidding,
                  isEnabled: data.isEnabled,
                  marketId: data.marketId,
                  marketUuid: data.marketUuid,
                  maxApr: data.maxApr,
                  maxDiscount: data.maxDiscount,
                },
              }
            : tm),
        }))
      );

      // subscribe to market stats
      // this will refetch stats queries after we know stats have updated
      subscribeToMarketStats({
        marketUuid: variables.marketUuid,
        takerId: variables.takerId,
      });
    },
  });
};

export default useUpdateOffer;
