import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useUserDetails from '@/data/useUserDetails';
import QueryBoundaries from './QueryBoundaries';

const DefaultTranslationInitializerComponent = () => {
  const { i18n } = useTranslation();
  const { data: locale } = useUserDetails((data) => data.me?.personalInfo?.locale);

  useLayoutEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  return null;
};

const DefaultTranslationInitializer = () => (
  <QueryBoundaries>
    <DefaultTranslationInitializerComponent />
  </QueryBoundaries>
);

export default DefaultTranslationInitializer;
