import { useTranslation } from 'react-i18next';
import { Chip } from '@c2fo/liquidity';
import { RecurringRule } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import getMarketType from '@/utils/getMarketType';

export const RecurringRulesCountChip = ({ count, onClick }: { count: number; onClick?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={`${count} ${count === 1 ? t('recurringRules.recurringRule') : t('recurringRules.title')}`}
      size="sm"
      type="info"
      variant="outlined"
      {...(onClick && { onClick })}
    />
  );
};

const RecurringRulesSummaryChip = ({
  rule,
  takerMarket,
  onEdit,
}: {
  rule: RecurringRule;
  takerMarket: TakerMarket;
  onEdit: ({ takerMarkets, type }: { takerMarkets: TakerMarket[]; type?: TakerMarketGroupType }) => void;
}) => {
  if (!rule) {
    return null;
  }

  const modalProps = {
    takerMarkets: [takerMarket],
    type: getMarketType(takerMarket),
  };

  return (
    <div className="mt-1">
      <RecurringRulesCountChip count={rule.count} onClick={() => onEdit(modalProps)} />
    </div>
  );
};

export default RecurringRulesSummaryChip;
