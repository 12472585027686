import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useExchangeRate from '@/data/useExchangeRate';
import useSEAUserSuppliers from '@/data/useSEAUserSuppliers';
import useTakerMarkets, { TakerMarket, fetchTakerMarkets } from '@/data/useTakerMarkets';
import useFeature, { Features } from '@/lib/features';
import { useReporting } from '@/reporting';

interface MarketStat {
  marketUuid: string;
  takerId: number;
  time: number;
}

export interface ServerSentEventListeners {
  marketStats: MarketStat[];
}

export const useRefetchStatsQueries = () => {
  const queryClient = useQueryClient();
  const usdRates = useExchangeRate('USD').data;
  const { data: takerMarkets } = useTakerMarkets();
  const seaUserSuppliers = useSEAUserSuppliers();
  const [enableSEApi] = useFeature(Features['enterprise-ui_enableSupplierExperienceApiPriceDiscoveryOfferReads']);

  const refetchAllStats = useCallback(async () => {
    await queryClient.refetchQueries({ queryKey: ['eligible-invoices'] });
    await queryClient.refetchQueries({ queryKey: ['taker-markets'] });
    await queryClient.refetchQueries({ queryKey: ['taker-market-stats'] });
  }, [queryClient]);

  const refetchOneTakerMarket = useCallback(
    async ({ marketUuid, takerId }: { marketUuid: string; takerId: number }) => {
      const takerMarket = takerMarkets?.find((tm) => tm.marketUuid === marketUuid && tm.takerDivisionId === takerId);

      if (!takerMarket) {
        return;
      }

      try {
        const updatedTakerMarkets = await fetchTakerMarkets(enableSEApi, seaUserSuppliers.data, usdRates, {
          filter: enableSEApi
            ? {
                makerOrganizationUuids: [takerMarket.makerOrganizationUuid],
                supplierMarkets: [
                  {
                    marketUuid: takerMarket.marketUuid,
                    supplierDivisionUuid: takerMarket.takerDivisionUuid,
                  },
                ],
              }
            : {
                marketUuid: { eq: takerMarket.marketUuid },
                supplierDivisionId: { eq: takerMarket.takerDivisionId },
              },
        });
        const updatedTakerMarket = updatedTakerMarkets.find(
          (tm) => tm.marketUuid === marketUuid && tm.takerDivisionId === takerId
        );

        if (updatedTakerMarket) {
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets = []) => {
            const takerMarketIndex = prevTakerMarkets.findIndex(
              (ptm) =>
                ptm.marketUuid === updatedTakerMarket.marketUuid &&
                ptm.takerDivisionId === updatedTakerMarket?.takerDivisionId
            );

            if (takerMarketIndex !== -1) {
              const newTakerMarkets = [...prevTakerMarkets];
              newTakerMarkets[takerMarketIndex] = updatedTakerMarket;
              return newTakerMarkets;
            }

            return prevTakerMarkets;
          });
        }
      } catch (error) {
        console.log(error);
      }

      await queryClient.refetchQueries({
        queryKey: ['eligible-invoices', takerMarket.marketId, takerMarket.takerDivisionId],
      });
      await queryClient.refetchQueries({
        queryKey: ['taker-market-stats', takerMarket.marketId, takerMarket.takerDivisionId],
      });
    },
    [queryClient, takerMarkets, usdRates, enableSEApi, seaUserSuppliers.data]
  );

  return {
    refetchAllStats,
    refetchOneTakerMarket,
  };
};

// store in react query for any taker market the FE is waiting to receive a stats update for
export const useMarketStatsStore = () => {
  const { track } = useReporting();
  const queryClient = useQueryClient();
  const { refetchOneTakerMarket, refetchAllStats } = useRefetchStatsQueries();

  const updateMarketStatsStore = ({ marketUuid, takerId }: { marketUuid: string; takerId: number | number[] }) => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);

    const foundMarketStat = sseListeners?.marketStats.find((marketStat) => {
      if (Array.isArray(takerId)) {
        return marketStat.marketUuid === marketUuid && takerId.includes(marketStat.takerId);
      }

      if (typeof takerId === 'number') {
        return marketStat.marketUuid === marketUuid && marketStat.takerId === takerId;
      }
    });

    if (foundMarketStat) {
      if (Array.isArray(takerId)) {
        track('stats-update::in-time', { marketUuid: foundMarketStat.marketUuid, takerId: foundMarketStat.takerId });
      }

      if (typeof takerId === 'number') {
        track('stats-update::long', { marketUuid: foundMarketStat.marketUuid, takerId: foundMarketStat.takerId });
      }

      // remove the found market stats listener
      queryClient.setQueryData<ServerSentEventListeners>(['server-sent-event-listeners'], (prevSseListeners) => {
        return {
          ...prevSseListeners,
          marketStats: (prevSseListeners?.marketStats ?? []).filter(
            (marketStat) =>
              marketStat.marketUuid !== foundMarketStat.marketUuid ||
              marketStat.takerId !== foundMarketStat.takerId ||
              marketStat.time !== foundMarketStat.time
          ),
        };
      });

      if (
        ['global-exclude-invoice-mutation', 'global-reinclude-invoice-mutation'].includes(foundMarketStat.marketUuid)
      ) {
        refetchAllStats();
      } else {
        refetchOneTakerMarket({ marketUuid: foundMarketStat.marketUuid, takerId: foundMarketStat.takerId });
      }
    }
  };

  return updateMarketStatsStore;
};

export const useServerSideEventListeners = () => {
  const queryClient = useQueryClient();
  const updateMarketStatsStore = useMarketStatsStore();

  const listenToMarketStats = ({ marketUuid, takerId }: { marketUuid: string; takerId: number }) => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);
    return sseListeners?.marketStats.some(
      (marketStat) =>
        (marketStat.marketUuid === marketUuid && marketStat.takerId === takerId) ||
        // NOTE: the following are not real marketUuids/takerIds, these are specific hard-coded strings
        // used only within the frontend to tap into server sent event listeners for global actions
        (marketStat.marketUuid === 'global-reinclude-invoice-mutation' && marketStat.takerId === 0) ||
        (marketStat.marketUuid === 'global-exclude-invoice-mutation' && marketStat.takerId === 0)
    );
  };

  const notifyMarketStatsSubscribers = ({ marketUuid, takerIds }: { marketUuid: string; takerIds: number[] }) => {
    updateMarketStatsStore({ marketUuid, takerId: takerIds });
  };

  const subscribeToMarketStats = ({ marketUuid, takerId }: { marketUuid: string; takerId: number }) => {
    queryClient.setQueryData<ServerSentEventListeners>(['server-sent-event-listeners'], (prevSseListeners) => ({
      ...prevSseListeners,
      marketStats: [
        ...(prevSseListeners?.marketStats ?? []),
        {
          marketUuid,
          takerId,
          time: Date.now(),
        },
      ],
    }));
  };

  return {
    listenToMarketStats,
    notifyMarketStatsSubscribers,
    subscribeToMarketStats,
  };
};
