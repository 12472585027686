import { useQueries } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

export interface RecurringRuleData {
  id: string;
  marketId: number;
  takerId: number;
  gteDpe?: number;
  lteDpe?: number;
  gtDueDate?: string;
  ltDueDate?: string;
  gteInvoiceAmount?: number;
  lteInvoiceAmount?: number;
  excludedVoucherIds?: string[];
}

export type RecurringRuleCategory = 'dpe' | 'invoiceId' | 'amount' | 'dueDate';

export type RecurringRuleCriteria = Exclude<keyof RecurringRuleData, 'id' | 'marketId' | 'takerId'>;

interface FormattedRecurringRule {
  amount?: {
    gteInvoiceAmount?: RecurringRuleData['gteInvoiceAmount'];
    lteInvoiceAmount?: RecurringRuleData['lteInvoiceAmount'];
  };
  dpe?: {
    gteDpe?: RecurringRuleData['gteDpe'];
    lteDpe?: RecurringRuleData['lteDpe'];
  };
  dueDate?: {
    gtDueDate?: RecurringRuleData['gtDueDate'];
    ltDueDate?: RecurringRuleData['ltDueDate'];
  };
  excludedVoucherIds?: RecurringRuleData['excludedVoucherIds'];
}

export interface RecurringRule extends FormattedRecurringRule {
  id: RecurringRuleData['id'];
  count: number;
  currency: TakerMarket['currency'];
  makerOrganizationUuid: TakerMarket['makerOrganizationUuid'];
  marketId: RecurringRuleData['marketId'];
  marketUuid: TakerMarket['marketUuid'];
  takerId: RecurringRuleData['takerId'];
  takerUuid: TakerMarket['takerDivisionUuid'];
}

export interface TakerMarketWithRecurringRule {
  rule: RecurringRule;
  takerMarket: TakerMarket;
}

/**
 * formats recurring rule data for frontend use
 * input = { gteDpe: 8, lteDpe: 24, lteInvoiceAmount: 1000 }
 * output = {
 *   dpe: { gteDpe: 8, lteDpe: 24 },
 *   amount: { lteInvoiceAmount: 1000 }
 * }
 */
const formatAsRecurringRule = (rule?: RecurringRuleData): FormattedRecurringRule | undefined => {
  if (!rule) {
    return;
  }

  const formattedRecurringRule: FormattedRecurringRule = {};

  if (rule.gteDpe || rule.lteDpe) {
    formattedRecurringRule.dpe = {
      ...(rule.gteDpe ? { gteDpe: rule.gteDpe } : {}),
      ...(rule.lteDpe ? { lteDpe: rule.lteDpe } : {}),
    };
  }

  if (rule.gtDueDate || rule.ltDueDate) {
    formattedRecurringRule.dueDate = {
      ...(rule.gtDueDate ? { gtDueDate: rule.gtDueDate } : {}),
      ...(rule.ltDueDate ? { ltDueDate: rule.ltDueDate } : {}),
    };
  }

  if (rule.gteInvoiceAmount || rule.lteInvoiceAmount) {
    formattedRecurringRule.amount = {
      ...(rule.gteInvoiceAmount ? { gteInvoiceAmount: rule.gteInvoiceAmount } : {}),
      ...(rule.lteInvoiceAmount ? { lteInvoiceAmount: rule.lteInvoiceAmount } : {}),
    };
  }

  if (rule.excludedVoucherIds) {
    formattedRecurringRule.excludedVoucherIds = rule.excludedVoucherIds;
  }

  return formattedRecurringRule;
};

const fetchRecurringRules = async (
  makerOrganizationUuid: string,
  takerMarkets: TakerMarket[]
): Promise<TakerMarketWithRecurringRule[]> => {
  const data = await apiClient
    .get(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules`)
    .json<RecurringRuleData[]>();

  return data.map((rule) => {
    const takerMarket = takerMarkets.find(
      (takerMarket) =>
        takerMarket.makerOrganizationUuid === makerOrganizationUuid &&
        takerMarket.takerDivisionId === rule.takerId &&
        takerMarket.legacyMarketId === rule.marketId
    )!;

    const formattedRecurringRule = formatAsRecurringRule(rule);

    return {
      rule: {
        id: rule.id,
        count: Object.keys(formattedRecurringRule ?? {}).length,
        currency: takerMarket.currency,
        makerOrganizationUuid,
        marketId: rule.marketId,
        marketUuid: takerMarket.marketUuid,
        takerId: rule.takerId,
        takerUuid: takerMarket.takerDivisionUuid,
        ...formattedRecurringRule,
      },
      takerMarket,
    } satisfies TakerMarketWithRecurringRule;
  });
};

const useRecurringRules = () => {
  const { data: takerMarkets = [] } = useTakerMarkets();
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');

  const uniqueMakerOrganizationUuids = [
    ...new Set(takerMarkets.map((takerMarket) => takerMarket.makerOrganizationUuid)),
  ];

  const rules = useQueries({
    queries:
      uniqueMakerOrganizationUuids && uniqueMakerOrganizationUuids.length > 0
        ? uniqueMakerOrganizationUuids.map((makerOrganizationUuid) => {
            return {
              queryKey: ['recurring-rules', makerOrganizationUuid],
              queryFn: () => fetchRecurringRules(makerOrganizationUuid, takerMarkets),
              enabled: enableRecurringRules,
            };
          })
        : [],
  });

  return rules.filter((rule) => rule.isSuccess).flatMap((rule) => rule.data);
};

export default useRecurringRules;
