import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import {
  AlarmClockIcon,
  AngleRightIcon,
  CalendarIcon,
  MoneyBillWaveIcon,
  SquareQuoteIcon,
} from '@c2fo/liquidity/icons';
import windTurbine from '@/assets/windturbine.svg?url';
import { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { RecurringRulesCountChip } from '@/features/takerMarketTable/components/RecurringRulesSummaryChip';
import { defaultRuleValues, RecurringRuleFormValues, useRecurringRuleFormContext } from '../RecurringRulesForm';
import { RecurringRulesFormState } from '../utils/recurringRulesFormReducer';
import useGetRecurringRulesForTakerMarkets from '../utils/useGetRecurringRulesForTakerMarkets';
import { mapRecurringRuleToFormCriteria } from '../utils/utils';
import { getRecurringRuleForCategory } from './DivisionSelectTable';
import RuleChips from './RuleChips';

interface RuleCategoryOption {
  count: number;
  description: string;
  icon: React.ElementType;
  label: string;
  value: RecurringRuleFormValues['category'];
}

interface CategorySelectProps {
  advanceStep: (category: RecurringRuleFormValues['category']) => void;
  isForSingleDivision: boolean;
  setEditMode: (isEditMode: boolean) => void;
  takerMarkets: TakerMarket[];
  display?: RecurringRulesFormState['display'];
  readOnly?: boolean;
}

export const getExistingRuleCountByCategory = (rules: RecurringRule[]): Record<RecurringRuleCategory, number> =>
  rules.reduce(
    (countByRule, rule) => {
      Object.keys(rule).forEach((key) => {
        if (['amount', 'dpe', 'dueDate', 'excludedVoucherIds'].includes(key)) {
          if (key === 'excludedVoucherIds') {
            countByRule.invoiceId += 1;
          } else {
            countByRule[key as RecurringRuleCategory] += 1;
          }
        }
      });

      return countByRule;
    },
    {
      amount: 0,
      dpe: 0,
      dueDate: 0,
      invoiceId: 0,
    }
  );

const CategorySelectLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-10 mt-3">
        <img src={windTurbine} alt={t('recurringRules.createRecurringRule')} className="mx-auto h-16 w-16" />
      </div>
      {children}
    </div>
  );
};

const CategorySelectContent = ({
  existingRules,
  isSingleDivision,
  option,
  readOnly = false,
}: {
  existingRules: RecurringRule[];
  isSingleDivision: boolean;
  option: RuleCategoryOption;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const existingRuleForCategory = getRecurringRuleForCategory(existingRules[0], option.value);
  const Icon = option.icon;

  return (
    <>
      <div className="flex items-center gap-4">
        <Icon
          className={cn('h-9 w-9 shrink-0 fill-text-secondary', {
            'transition-colors duration-200 group-hover:fill-lightBlue-500': !readOnly,
          })}
        />
        <div className="text-left">
          <div className="font-medium">{option.label}</div>
          <div className="text-sm text-text-secondary">{option.description}</div>
          {option.count > 0 ? (
            <div className="mt-1">
              {isSingleDivision && existingRuleForCategory ? (
                <RuleChips recurringRule={existingRuleForCategory} readOnly shortForm />
              ) : (
                <RecurringRulesCountChip count={option.count} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {isSingleDivision && option.count > 0 ? (
        <div
          className={cn('ml-6 flex items-center justify-center rounded-full px-3 py-1 font-bold text-text-disabled', {
            'text-secondary-500 transition-colors duration-200 group-hover:bg-gray-200': !readOnly,
          })}
        >
          {t('core.edit')}
        </div>
      ) : (
        <div
          className={cn('ml-6 flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-gray-200', {
            'transition-colors duration-200 group-hover:bg-gray-400': !readOnly,
          })}
        >
          <AngleRightIcon className="h-6 w-6 fill-secondary-500" />
        </div>
      )}
    </>
  );
};

const ReadOnlyCategorySelect = ({
  existingRules,
  existingRuleCountByCategory,
  isForSingleDivision,
  ruleCategoryOptions,
}: {
  existingRules: RecurringRule[];
  existingRuleCountByCategory: Record<RecurringRuleCategory, number>;
  isForSingleDivision: boolean;
  ruleCategoryOptions: RuleCategoryOption[];
}) => {
  return (
    <div className="flex flex-col space-y-3">
      {ruleCategoryOptions.map((option) => (
        <div
          key={option.value}
          className={cn(
            'group relative flex w-full items-center justify-between rounded border border-transparent bg-canvas px-4 py-5 shadow',
            { 'opacity-38': !existingRuleCountByCategory[option.value] }
          )}
        >
          <CategorySelectContent
            existingRules={existingRules}
            isSingleDivision={isForSingleDivision}
            option={option}
            readOnly
          />
        </div>
      ))}
    </div>
  );
};

const InteractiveCategorySelect = ({
  advanceStep,
  display,
  isForSingleDivision,
  takerMarkets,
  setEditMode,
  existingRules,
  ruleCategoryOptions,
}: CategorySelectProps & {
  existingRules: RecurringRule[];
  ruleCategoryOptions: RuleCategoryOption[];
}) => {
  const { t } = useTranslation();
  const { setValue } = useRecurringRuleFormContext();

  const onCategorySelect = (value: RecurringRuleFormValues['category']) => {
    setValue('category', value);

    // if for single division, consider existing rule
    if (isForSingleDivision) {
      const existingRuleCriteria = existingRules.length > 0 ? mapRecurringRuleToFormCriteria(existingRules[0]) : null;
      setValue('takerMarkets', takerMarkets);

      // set criteria and edit mode based on existing rule
      if (existingRuleCriteria?.[value]) {
        setValue('criteria', existingRuleCriteria[value]);
        setEditMode(true);
      } else {
        setValue('criteria', defaultRuleValues[value].criteria);
        setEditMode(false);
      }
    } else {
      setValue('takerMarkets', defaultRuleValues[value].takerMarkets);
      setValue('criteria', defaultRuleValues[value].criteria);
      setEditMode(false);
    }

    advanceStep(value);
  };

  return (
    <>
      <div className="mb-4">
        {display === 'createAnother'
          ? t('recurringRules.createAnotherRuleDescription')
          : t('recurringRules.createARuleDescription')}
      </div>
      <div className="flex flex-col space-y-3">
        {ruleCategoryOptions.map((option) => (
          <button
            key={option.value}
            aria-label={option.label}
            className="group relative flex w-full items-center justify-between rounded border border-transparent bg-canvas px-4 py-5 shadow transition-colors duration-200 hover:border-lightBlue-500 focus-visible:border-lightBlue-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-lightBlue-500"
            onClick={() => onCategorySelect(option.value)}
            type="button"
          >
            <CategorySelectContent
              existingRules={existingRules}
              isSingleDivision={isForSingleDivision}
              option={option}
            />
          </button>
        ))}
      </div>
    </>
  );
};

const CategorySelect = ({
  advanceStep,
  display,
  isForSingleDivision = false,
  readOnly = false,
  setEditMode,
  takerMarkets,
}: CategorySelectProps) => {
  const { t } = useTranslation();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const existingRules = getRecurringRulesForTakerMarkets(takerMarkets);
  const existingRuleCountByCategory = getExistingRuleCountByCategory(existingRules);

  const ruleCategoryOptions: RuleCategoryOption[] = [
    {
      description: t('recurringRules.dpe.category.description'),
      icon: CalendarIcon,
      label: t('core.daysPaidEarly'),
      value: 'dpe',
      count: existingRuleCountByCategory.dpe,
    },
    {
      description: t('recurringRules.invoiceAmount.category.description'),
      icon: MoneyBillWaveIcon,
      label: t('core.invoiceAmount'),
      value: 'amount',
      count: existingRuleCountByCategory.amount,
    },
    {
      description: t('recurringRules.dueDate.category.description'),
      icon: AlarmClockIcon,
      label: t('recurringRules.dueDate.category.label'),
      value: 'dueDate',
      count: existingRuleCountByCategory.dueDate,
    },
    {
      description: t('recurringRules.invoiceId.category.description'),
      icon: SquareQuoteIcon,
      label: t('recurringRules.invoiceId.category.label'),
      value: 'invoiceId',
      count: existingRuleCountByCategory.invoiceId,
    },
  ];

  return readOnly ? (
    <CategorySelectLayout>
      <ReadOnlyCategorySelect
        existingRules={existingRules}
        existingRuleCountByCategory={existingRuleCountByCategory}
        isForSingleDivision={isForSingleDivision}
        ruleCategoryOptions={ruleCategoryOptions}
      />
    </CategorySelectLayout>
  ) : (
    <CategorySelectLayout>
      <InteractiveCategorySelect
        advanceStep={advanceStep}
        display={display}
        existingRules={existingRules}
        isForSingleDivision={isForSingleDivision}
        ruleCategoryOptions={ruleCategoryOptions}
        setEditMode={setEditMode}
        takerMarkets={takerMarkets}
      />
    </CategorySelectLayout>
  );
};

export default CategorySelect;
