import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { ExchangeRates } from '@/data/useExchangeRates';
import { RecurringRuleCriteria } from '@/data/useRecurringRules';
import convertCurrency from '@/utils/convertCurrency';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { useRecurringRuleFormContext } from '../RecurringRulesForm';
import useRuleFormContent from '../utils/useRuleFormContent';

const ReviewRule = ({ exchangeRates, toCurrency }: { exchangeRates: ExchangeRates; toCurrency: string }) => {
  const { t } = useTranslation();
  const { asCurrency, asNumber } = useLocaleFormat();
  const { getValues } = useRecurringRuleFormContext();
  const [category, criteria, takerMarkets] = getValues(['category', 'criteria', 'takerMarkets']);
  const { getRuleFormContent } = useRuleFormContent();
  const { review } = getRuleFormContent(category);

  const selectedDivisionCount = takerMarkets.length;
  const selectedDivisionText =
    selectedDivisionCount === 1
      ? t('core.divisionSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) })
      : t('core.divisionsSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) });

  const formatValue = ({ type, value }: { type: RecurringRuleCriteria; value: string }) => {
    if (type === 'gteInvoiceAmount' || type === 'lteInvoiceAmount') {
      return asCurrency(Number(value), toCurrency);
    } else if (type === 'gteDpe' || type === 'lteDpe') {
      return asNumber({ value: Number(value) });
    } else {
      return value;
    }
  };

  const uniqueSelectedCurrencies = [...new Set(takerMarkets.map(({ currency }) => currency))].filter(
    (currency) => currency !== toCurrency
  );

  return (
    <>
      <div>
        <div className="mb-2 font-medium">{t('core.summary')}</div>
        <div className="rounded-md bg-canvas text-sm">
          <div className="flex justify-between gap-4 border-b-[0.5px] border-stroke/30 p-4">
            <div>{t('core.divisions')}</div>
            <div className="font-bold">{selectedDivisionText}</div>
          </div>
          <div className="flex items-center justify-between gap-4 p-4">
            <div className="w-2/5">{t('recurringRules.rule')}</div>
            <div className="w-3/5 flex-wrap space-y-2 text-right">
              {criteria.map(({ type, value }) => (
                <Fragment key={type}>
                  {review[type] ? (
                    <div className="font-bold">{t(review[type], { value: formatValue({ type, value }) })}</div>
                  ) : null}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {uniqueSelectedCurrencies.length > 0 && category === 'amount' && (
        <div>
          <Alert
            type="default"
            title={t('recurringRules.reviewRule.currencyConversionTitle')}
            description={
              <>
                <div>
                  {uniqueSelectedCurrencies.length === 1
                    ? t('recurringRules.reviewRule.currencyConversionDescriptionSingle', { currency: toCurrency })
                    : t('recurringRules.reviewRule.currencyConversionDescriptionMultiple', { currency: toCurrency })}
                </div>
                <ul className="ml-4 mt-4 list-disc space-y-1">
                  {uniqueSelectedCurrencies.map((currency) => (
                    <li key={currency} className="space-x-1">
                      <span className="font-bold">
                        {currency} to {toCurrency}
                      </span>
                      <span>
                        {`(${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: toCurrency,
                            to: currency,
                          }),
                          currency
                        )} to ${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: currency,
                            to: currency,
                          }),
                          toCurrency
                        )})`}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        </div>
      )}
      <div>
        <ul className="ml-6 list-disc">
          <li>{t('recurringRules.reviewRule.listItem1')}</li>
          <li>{t('recurringRules.reviewRule.listItem2')}</li>
          <li>{t('recurringRules.reviewRule.listItem3')}</li>
          <li>{t('recurringRules.reviewRule.listItem4')}</li>
          <li>{t('recurringRules.reviewRule.listItem5')}</li>
          <li>{t('recurringRules.reviewRule.listItem6')}</li>
        </ul>
      </div>
    </>
  );
};

export default ReviewRule;
