import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { TimesIcon } from '@c2fo/liquidity/icons';
import DatePicker from '@/components/DatePicker';
import useAccountPreferredConfig from '@/data/useAccountPreferredConfig';
import useGlobalExcludeInvoices, {
  GlobalExcludeInvoiceParamsRule,
  GlobalExcludeInvoiceParamsRuleType,
} from '@/data/useGlobalExcludeInvoices';
import { useReporting } from '@/reporting';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { usePreferredOfferIsActive } from '@/utils/usePreferredOfferIsActive';

const GlobalExcludeInvoicesModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { mutateAsync: excludeGlobalInvoices, isLoading } = useGlobalExcludeInvoices();
  const { data: accountPreferredConfig } = useAccountPreferredConfig();
  const hasActivePreferredOffer = usePreferredOfferIsActive();
  const [dueBeforeDatePickerOpen, setDueBeforeDatePickerOpen] = useState<boolean>(false);
  const [dueAfterDatePickerOpen, setDueAfterDatePickerOpen] = useState<boolean>(false);

  const ruleOptions: { name: string; type: GlobalExcludeInvoiceParamsRuleType }[] = [
    { name: t('invoiceSettings.rules.amountGreater'), type: 'amount-greater' },
    { name: t('invoiceSettings.rules.amountLess'), type: 'amount-less' },
    { name: t('invoiceSettings.rules.dpeGreater'), type: 'dpe-greater' },
    { name: t('invoiceSettings.rules.dpeLess'), type: 'dpe-less' },
    { name: t('invoiceSettings.rules.dueBefore'), type: 'due-before' },
    { name: t('invoiceSettings.rules.dueAfter'), type: 'due-after' },
  ];

  const [selectedRules, setSelectedRules] = useState<GlobalExcludeInvoiceParamsRule[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedRules([{ type: ruleOptions[0].type, value: null }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const addSelectedRule = () => {
    const selectedRulesMap = selectedRules.map(({ type }) => type);
    const ruleOptionsMap = ruleOptions.filter(({ type }) => !selectedRulesMap.includes(type));
    setSelectedRules([...selectedRules, { type: ruleOptionsMap[0].type, value: null }]);
  };

  const removeSelectedRule = (type: string) => {
    if (selectedRules.length > 1) {
      setSelectedRules(selectedRules.filter((sr) => sr.type !== type));
    }
  };

  const handleRuleChange = (
    value: GlobalExcludeInvoiceParamsRuleType | string,
    ruleType: GlobalExcludeInvoiceParamsRuleType,
    key: 'type' | 'value'
  ) => {
    setSelectedRules((selectedRule) =>
      selectedRule.map((rule) => {
        if (rule.type === ruleType) {
          return {
            ...rule,
            [key]: value,
            ...(['due-before', 'due-after'].includes(value) && {
              value: dateAsIsoString(),
            }),
          };
        }

        return rule;
      })
    );

    setErrors((errors) => errors.filter((error) => error !== ruleType));
  };

  const handleExcludeInvoices = async () => {
    const rules = selectedRules.map((selectedRule) => {
      if (['due-before', 'due-after'].includes(selectedRule.type)) {
        return selectedRule;
      }

      return {
        ...selectedRule,
        value: Number(selectedRule.value),
      };
    });

    const hasErrors = rules.filter((rule) => !rule.value).map(({ type }) => type);

    if (hasErrors.length) {
      return setErrors(hasErrors);
    }

    await excludeGlobalInvoices({ currency: 'USD', rules });

    onClose();
    track('exclude-invoices::submitted', { rules });
  };

  const toggleDatePicker = (ruleType: GlobalExcludeInvoiceParamsRuleType) => {
    if (ruleType === 'due-before') {
      return setDueBeforeDatePickerOpen(!dueBeforeDatePickerOpen);
    }

    return setDueAfterDatePickerOpen(!dueAfterDatePickerOpen);
  };

  const handleDateSelect = (ruleType: GlobalExcludeInvoiceParamsRuleType, date?: Date) => {
    const dateString = dateAsIsoString(date);
    handleRuleChange(dateString, ruleType, 'value');
    toggleDatePicker(ruleType);
  };

  return (
    <Modal className="w-fit" open={open}>
      <div className="flex justify-center p-6">
        <div className="w-full max-w-4xl">
          <div className="relative bg-white text-text-primary">
            <div>
              <div className="text-xl font-medium capitalize">{t('invoiceSettings.modal.title')}</div>
              <div className="absolute -right-3 -top-3">
                <button className="rounded-md p-2 text-xl hover:bg-gray-100" type="button" onClick={onClose}>
                  <TimesIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-6 py-8">
            <div>{t('invoiceSettings.modal.description')}</div>
            <div className="space-y-2">
              {selectedRules.map((rule, idx) => {
                // filters any already selected rules from the available select options
                const selectedRulesMap = selectedRules.filter(({ type }) => type !== rule.type).map(({ type }) => type);
                const ruleOptionsMap = ruleOptions.filter(({ type }) => !selectedRulesMap.includes(type));

                return (
                  <div
                    key={rule.type}
                    className="flex items-start justify-between rounded-md bg-gray-100 p-4 lg:items-center"
                  >
                    {/* rule */}
                    <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
                      <div className="shrink-0 font-medium">
                        {idx === 0
                          ? t('invoiceSettings.modal.rulePredicate')
                          : t('invoiceSettings.modal.ruleConjunction')}
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:items-center">
                        {/* select */}
                        <div>
                          <select
                            aria-label="Rule Select"
                            className="h-9 w-96 overflow-hidden text-ellipsis rounded-md border px-2 max-[540px]:w-56"
                            onChange={(e) => handleRuleChange(e.target.value, rule.type, 'type')}
                            value={rule.type}
                          >
                            {ruleOptionsMap.map((option) => (
                              <option key={option.type} value={option.type}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* input */}
                        <div className="flex items-center gap-2">
                          {['due-before', 'due-after'].includes(rule.type) ? (
                            <DatePicker
                              className="z-10"
                              open={rule.type === 'due-before' ? dueBeforeDatePickerOpen : dueAfterDatePickerOpen}
                              onOpenChange={() => toggleDatePicker(rule.type)}
                              onSelect={(date) => handleDateSelect(rule.type, date)}
                              selected={rule.value as string | null}
                              trigger={
                                <div
                                  aria-label={rule.type}
                                  className="flex h-9 w-32 items-center rounded-md border bg-white px-2"
                                  role="button"
                                >
                                  {rule.value}
                                </div>
                              }
                            />
                          ) : (
                            <>
                              <div>
                                <input
                                  aria-label={rule.type}
                                  type="text"
                                  className={cn('h-9 w-32 rounded-md border px-2', {
                                    'border-red-500': errors.includes(rule.type),
                                  })}
                                  onChange={(e) => handleRuleChange(e.target.value, rule.type, 'value')}
                                />
                              </div>
                              {/* amount input addon */}
                              {['amount-greater', 'amount-less'].includes(rule.type) && (
                                <>
                                  <div>USD</div>
                                </>
                              )}
                              {/* dpe input addon */}
                              {['dpe-greater', 'dpe-less'].includes(rule.type) && <div>{t('core.days')}</div>}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* remove button */}
                    <div className="ml-10 text-right">
                      {selectedRules.length > 1 && (
                        <button
                          aria-label="Remove Rule"
                          className="rounded-md p-2 text-xl transition-all duration-200 hover:bg-gray-300"
                          type="button"
                          onClick={() => removeSelectedRule(rule.type)}
                        >
                          <TimesIcon fill={colors.gray[500]} />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="pt-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={addSelectedRule}
                  disabled={selectedRules.length === ruleOptions.length}
                >
                  {t('invoiceSettings.modal.add')}
                </Button>
              </div>
            </div>
            <div className="space-y-2">
              <div className="italic">{`${t('invoiceSettings.modal.disclaimer')}.`}</div>
              {hasActivePreferredOffer && accountPreferredConfig?.canEditInvoices !== true && (
                <div className="italic">{t('invoiceSettings.modal.disclaimerPreferred')}</div>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <Button variant="primary" onClick={handleExcludeInvoices} loading={isLoading}>
              {t('invoiceSettings.modal.cta')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GlobalExcludeInvoicesModal;
