import { useMutation } from '@tanstack/react-query';
import useRefetchAndSubscribeForRules from '@/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import apiClient from '@/lib/apiClient';
import { RecurringRuleArgument } from './useCreateRecurringRule';

export interface UpdateRecurringRuleArgument extends Omit<RecurringRuleArgument, 'takersMarkets'> {
  id: string;
  marketId: number;
  marketUuid: string;
  takerId: number;
  takerUuid: string;
}

export interface UpdateRecurringRuleMap {
  gteDpe?: {
    value: number | null;
  };
  lteDpe?: {
    value: number | null;
  };
  gtDueDate?: {
    value: string | null;
  };
  ltDueDate?: {
    value: string | null;
  };
  gteInvoiceAmount?: {
    value: number | null;
  };
  lteInvoiceAmount?: {
    value: number | null;
  };
  excludedVoucherIds?: {
    value: string[] | null;
  };
}

const useUpdateRecurringRule = () => {
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['updateRecurringRule'],
    mutationFn: (rule: UpdateRecurringRuleArgument) => {
      const { id, makerOrganizationUuid, ...ruleArgs } = rule;

      /**
       * formats provided argument for backend use
       * includes formatting for null values to ensure any removed criteria are updated correctly
       */
      const args = {
        ...(ruleArgs.gteDpe || ruleArgs.lteDpe
          ? {
              gteDpe: {
                value: ruleArgs.gteDpe ?? null,
              },
              lteDpe: {
                value: ruleArgs.lteDpe ?? null,
              },
            }
          : {}),
        ...(ruleArgs.gtDueDate || ruleArgs.ltDueDate
          ? {
              gtDueDate: {
                value: ruleArgs.gtDueDate ?? null,
              },
              ltDueDate: {
                value: ruleArgs.ltDueDate ?? null,
              },
            }
          : {}),
        ...(ruleArgs.gteInvoiceAmount || ruleArgs.lteInvoiceAmount
          ? {
              gteInvoiceAmount: {
                value: ruleArgs.gteInvoiceAmount ?? null,
              },
              lteInvoiceAmount: {
                value: ruleArgs.lteInvoiceAmount ?? null,
              },
            }
          : {}),
        ...(ruleArgs.excludedVoucherIds
          ? {
              excludedVoucherIds: {
                value: ruleArgs.excludedVoucherIds ?? null,
              },
            }
          : {}),
      } satisfies UpdateRecurringRuleMap;

      return apiClient
        .patch(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`, {
          json: args,
        })
        .json();
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid, marketUuid, takerId } = variables;
      refetchAndSubscribeRules({ makerOrganizationUuid, takerMarkets: [{ marketUuid, takerId }] });
    },
  });
};

export default useUpdateRecurringRule;
