import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import { TakerMarket } from '@/data/useTakerMarkets';
import useTakerMarketsGroups, { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import FixedRateInfoPopover from '@/features/fixedRate/InfoPopover';
import FixedRateParticipationToggle from '@/features/fixedRate/ParticipationToggle';
import FixedRateSetOffer from '@/features/fixedRate/SetOffer';
import useFixedRateDetails from '@/features/fixedRate/useFixedRateDetails';
import useFeature from '@/lib/features';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useIsNewDivision from '@/utils/useIsNewDivision';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import RecurringRulesForm from '../recurringRules/RecurringRulesForm';
import useGetRecurringRulesForTakerMarkets from '../recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import NextMarketClearTimeCountdown from './components/NextMarketClearTimeCountdown';
import OfferDiscountAPRDisplay from './components/OfferDiscountAPRDisplay';
import RecurringRulesSummaryChip from './components/RecurringRulesSummaryChip';
import sortTakerMarketTable, { Sort, SortKey } from './utils/sortTakerMarketTable';

const FixedRateMarketTable = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { isNewDivision } = useIsNewDivision();
  const { getRestrictions } = useRestrictions();
  const { getFixedRateDetails } = useFixedRateDetails();
  const { asCurrency, asNumber } = useLocaleFormat();
  const { listenToMarketStats } = useServerSideEventListeners();
  const [editOffer, setEditOffer] = useState<TakerMarket | null>(null);
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const takerMarketsGroups = useTakerMarketsGroups();
  const fixedTakerMarkets = sortTakerMarketTable(takerMarketsGroups['FIXED'].takerMarkets, sort);
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const [recurringRulesModalOpen, setRecurringRulesModalOpen] = useState(false);
  const [ruleModalProps, setRuleModalProps] = useState<{
    takerMarkets: TakerMarket[];
    type?: TakerMarketGroupType;
  }>({ takerMarkets: [] });

  const isExpirationDateShown = fixedTakerMarkets?.some((takerMarket) => takerMarket.disableAfterMarketClearsDate);

  const handleSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'makerOrganizationName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const tableHeadColumns: { accessorKey: SortKey; content: ReactNode }[] = [
    {
      accessorKey: 'participation',
      content: t('core.participation'),
    },
    {
      accessorKey: 'makerOrganizationName',
      content: t('core.division'),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      content: t('taker.dictionary.availableAR.label'),
    },
    {
      accessorKey: 'marketPayDate',
      content: t('taker.dictionary.payDate.label'),
    },
    ...(isExpirationDateShown
      ? [
          {
            accessorKey: 'disableAfterMarketClearsDate' as SortKey,
            content: t('taker.dictionary.expirationDate.label'),
          },
        ]
      : []),
    {
      accessorKey: 'rateInfo.referenceRate',
      content: <FixedRateInfoPopover iconColor={colors.primary[800]} />,
    },
    {
      accessorKey: 'offerConfig',
      content: t('taker.dictionary.offer.label'),
    },
    {
      accessorKey: 'acceptedInvoiceAmount',
      content: t('taker.dictionary.clearing.label'),
    },
    {
      accessorKey: 'notAcceptedInvoiceAmount',
      content: t('taker.dictionary.nonClearing.label'),
    },
    {
      accessorKey: 'acceptedEarn',
      content: t('taker.dictionary.discount.label'),
    },
    {
      accessorKey: 'eligibleDpeWeightedAvg',
      content: t('taker.dictionary.dpe.label'),
    },
  ];

  const openEditOfferModal = (takerMarket: TakerMarket) => {
    setEditOffer(takerMarket);
    track('offer::fixed-rate::clicked');
  };

  const closeEditOfferModal = () => setEditOffer(null);

  const openRecurringRulesModal = ({
    takerMarkets,
    type,
  }: {
    takerMarkets: TakerMarket[];
    type?: TakerMarketGroupType;
  }) => {
    setRuleModalProps({ takerMarkets, type });
    setRecurringRulesModalOpen(true);
  };

  return (
    <>
      {fixedTakerMarkets && fixedTakerMarkets.length > 0 ? (
        <>
          <FixedRateSetOffer open={!!editOffer} takerMarket={editOffer} onClose={closeEditOfferModal} />
          <RecurringRulesForm
            isForSingleDivision
            onClose={() => setRecurringRulesModalOpen(false)}
            open={recurringRulesModalOpen}
            {...ruleModalProps}
          />
          <div className="w-full overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, content }) => (
                    <TableHead
                      key={accessorKey}
                      onClick={() => handleSort(accessorKey)}
                      {...(['makerOrganizationName'].includes(accessorKey) && { className: 'max-w-[224px]' })}
                      {...(['participation'].includes(accessorKey) && { className: 'w-[170px]' })}
                    >
                      <TableSortArrow
                        accessorKey={accessorKey}
                        sort={sort}
                        {...(!['makerOrganizationName', 'participation'].includes(accessorKey) && { textRight: true })}
                      >
                        {content}
                      </TableSortArrow>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {fixedTakerMarkets?.map((takerMarket) => {
                  const listeningForMarketStat = listenToMarketStats({
                    marketUuid: takerMarket.marketUuid,
                    takerId: takerMarket.takerDivisionId,
                  });
                  const { formattedMarketRate } = getFixedRateDetails(takerMarket);
                  const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);
                  const { canEditOffers } = getRestrictions([takerMarket]);

                  return (
                    <TableRow
                      key={takerMarket.id}
                      className={cn('transition-all duration-100', takerMarket.id === editOffer?.id && 'bg-gray-100')}
                    >
                      <TableCell>
                        {/* participation can only be toggled for a taker market that has had a taker config set */}
                        {takerMarket.offerConfig.id && (
                          <FixedRateParticipationToggle
                            openEditOfferModal={openEditOfferModal}
                            takerMarket={takerMarket}
                          />
                        )}
                        {takerMarket.offerConfig.id && !takerMarket.marketIsEnabled && (
                          <div className="pt-1 text-xs text-gray-800">{t('closedMarketMessaging.futureOffer')}</div>
                        )}
                      </TableCell>
                      <TableCell className="flex flex-row">
                        {isNewDivision(takerMarket.userAddedToDivision) && (
                          <div className="-ml-4 mr-2 flex h-6 items-center">
                            <div className="h-2 w-2 rounded-full bg-lightBlue-500">
                              <span className="sr-only">{t('core.newDivision')}</span>
                            </div>
                          </div>
                        )}
                        <div className="max-w-[224px] truncate">
                          <Link
                            className="font-bold"
                            to={`/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                          >
                            {getTakerMarketName(takerMarket)}
                          </Link>
                          <div className="truncate text-sm font-medium text-gray-600">
                            <span>{takerMarket.currency}</span>
                            <span className="mx-0.5 text-lg leading-4">&sdot;</span>
                            <span>
                              <NextMarketClearTimeCountdown nextClearTime={takerMarket.marketNextClearTime} />
                            </span>
                          </div>
                          <div
                            className="items-center truncate text-sm font-medium uppercase text-gray-600"
                            title={getTakerMarketDivisionTitle(takerMarket)?.title}
                          >
                            {getTakerMarketDivisionTitle(takerMarket)?.content}
                          </div>
                          {enableRecurringRules && (
                            <RecurringRulesSummaryChip
                              rule={recurringRule}
                              takerMarket={takerMarket}
                              onEdit={openRecurringRulesModal}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                      </TableCell>
                      <TableCell className="text-right">
                        {takerMarket.marketIsEnabled &&
                          takerMarket.marketPayDate &&
                          trimDateTimestamp(takerMarket.marketPayDate)}
                      </TableCell>
                      {isExpirationDateShown && (
                        <TableCell className="text-right">
                          {takerMarket.disableAfterMarketClearsDate
                            ? trimDateTimestamp(takerMarket.disableAfterMarketClearsDate)
                            : t('core.na')}
                        </TableCell>
                      )}
                      {/* Market Rate */}
                      <TableCell className="m-auto max-w-lg space-y-2">
                        <div className="flex items-center justify-end">{formattedMarketRate}</div>
                      </TableCell>
                      {/* first offer, taker market has never had a taker config set */}
                      {!takerMarket.offerConfig.id ? (
                        <TableCell colSpan={5} className="text-center">
                          {listeningForMarketStat ? (
                            <div className="text-center text-sm font-medium">
                              {t('taker.marketFeedback.calculating')}
                            </div>
                          ) : (
                            // first offer
                            <div className="m-auto max-w-lg space-y-2">
                              <div className="text-sm">
                                {takerMarket.eligibleInvoiceAmount <= 0
                                  ? t('taker.firstOfferDescriptionWithNoAp', {
                                      eligibleInvoiceAmount: asCurrency(
                                        takerMarket.eligibleInvoiceCount,
                                        takerMarket.currency
                                      ),
                                      makerName: takerMarket.makerDivisionName,
                                      currency: takerMarket.currency,
                                    })
                                  : t(
                                      takerMarket.eligibleInvoiceCount === 1
                                        ? 'taker.firstOfferDescriptionSingular'
                                        : 'taker.firstOfferDescription',
                                      {
                                        eligibleInvoiceCount: asNumber({
                                          value: takerMarket.eligibleInvoiceCount,
                                          decimals: 0,
                                        }),
                                        eligibleInvoiceAmount: asCurrency(
                                          takerMarket.eligibleInvoiceCount,
                                          takerMarket.currency
                                        ),
                                        makerName: takerMarket.makerDivisionName,
                                        currency: takerMarket.currency,
                                        eligibleDpeWeightedAvg: asNumber({
                                          value: takerMarket.eligibleDpeWeightedAvg,
                                          decimals: 0,
                                        }),
                                      }
                                    )}
                              </div>
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                                >
                                  <Button size="sm" variant="secondary">
                                    {t('core.viewInvoices')}
                                  </Button>
                                </Link>
                                <Button
                                  onClick={() => openEditOfferModal(takerMarket)}
                                  disabled={!canEditOffers}
                                  size="sm"
                                  variant="primary"
                                >
                                  {t('experiments.initialOfferButtonText.set')}
                                </Button>
                              </div>
                            </div>
                          )}
                        </TableCell>
                      ) : (
                        <>
                          {/* Offer */}
                          <TableCell className="text-right">
                            <div className="flex items-center justify-end">
                              <OfferDiscountAPRDisplay
                                isDiscountBidding={takerMarket.offerConfig.isDiscountBidding}
                                maxDiscount={takerMarket.offerConfig.maxDiscount}
                                maxApr={takerMarket.offerConfig.maxApr}
                                onClick={() => openEditOfferModal(takerMarket)}
                                readOnly={!canEditOffers}
                              />
                            </div>
                          </TableCell>
                          {takerMarket.marketIsEnabled && !takerMarket.offerConfig.isEnabled ? (
                            <TableCell colSpan={3} className="text-center text-sm font-medium text-red-500">
                              {t('taker.marketsGridNotParticipating')}
                            </TableCell>
                          ) : listeningForMarketStat ? (
                            <TableCell colSpan={3} className="text-center text-sm font-medium">
                              {t('taker.marketFeedback.calculating')}
                            </TableCell>
                          ) : (
                            <>
                              <TableCell className="text-right">
                                {asCurrency(takerMarket.acceptedInvoiceAmount, takerMarket.currency)}
                              </TableCell>
                              <TableCell className="text-right">
                                {asCurrency(takerMarket.notAcceptedInvoiceAmount, takerMarket.currency)}
                              </TableCell>
                              <TableCell className="text-right">
                                {asCurrency(takerMarket.acceptedEarn, takerMarket.currency)}
                              </TableCell>
                            </>
                          )}
                          <TableCell className="text-right">
                            {asNumber({ value: takerMarket.eligibleDpeWeightedAvg, decimals: 0 })}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <div className="p-4">
          <div className="rounded-md bg-gray-100 p-4 text-center text-sm text-gray-700">
            {t('closedMarketMessaging.noActiveMarkets')}
          </div>
        </div>
      )}
    </>
  );
};

export default FixedRateMarketTable;
