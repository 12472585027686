import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { CircleCheckIcon, WalletIcon } from '@c2fo/liquidity/icons';
import useUserDetails from '@/data/useUserDetails';
import { useReporting } from '@/reporting';
import { usePreferredOffersNearTermEnd } from '@/utils/preferredOfferRenewal';
import { getTokenContent } from '@/utils/token';

const RenewalRequestModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [hasRequestedRenewal, setHasRequestedRenewal] = useState(false);
  const { t } = useTranslation();
  const { track } = useReporting();
  const tokenContent = getTokenContent();
  const { data: userData } = useUserDetails();
  const { takerMarketsNearTermEnd } = usePreferredOffersNearTermEnd();

  const title = hasRequestedRenewal
    ? t('preferredRenewalModal.confirmation.title')
    : t('preferredRenewalModal.request.title');

  const RenewalRequestContent = () => (
    <>
      <div className="flex justify-center">
        <WalletIcon className="h-20 w-auto" />
      </div>
      <div>{t('preferredRenewalModal.request.description')}</div>
      <div>{t('preferredRenewalModal.request.instruction')}</div>
    </>
  );

  const RenewalConfirmationContent = () => (
    <>
      <div className="flex justify-center">
        <CircleCheckIcon className="h-20 w-auto" />
      </div>
      <div>{t('preferredRenewalModal.confirmation.description')}</div>
    </>
  );

  const handleRenewalRequest = () => {
    const preferredOfferRenewalRequest = {
      userUuid: tokenContent?.payload?.user?.uuid,
      accountId: userData?.me?.account?.id,
      srmEmail: userData?.me?.account?.srm?.emailAddress,
      divisionMarketUuids: takerMarketsNearTermEnd.map((tm) => {
        return {
          divisionUuid: tm.takerDivisionUuid,
          marketUuid: tm.marketUuid,
        };
      }),
    };

    track('preferred-offer::renewal::requested', { preferredOfferRenewalRequest });
    setHasRequestedRenewal(true);
  };

  return (
    <Modal onClose={onClose} open={open} size="sm">
      <ModalTitleInfo>{title}</ModalTitleInfo>
      <ModalContent center>
        {hasRequestedRenewal ? <RenewalConfirmationContent /> : <RenewalRequestContent />}
      </ModalContent>
      <ModalActions className="sm:justify-center">
        <Button
          variant={hasRequestedRenewal ? 'secondary' : 'cta'}
          onClick={hasRequestedRenewal ? onClose : handleRenewalRequest}
          className="sm:w-1/2"
          disabled={!hasRequestedRenewal && (!userData || !takerMarketsNearTermEnd.length)}
        >
          {hasRequestedRenewal ? t('core.close') : t('preferredRenewalModal.request.action')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default RenewalRequestModal;
