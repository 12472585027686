import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useRecurringRuleFormContext } from '../RecurringRulesForm';
import useGetRecurringRulesForTakerMarkets from '../utils/useGetRecurringRulesForTakerMarkets';
import useRuleFormContent from '../utils/useRuleFormContent';

const ExistingRulesAlert = ({ takerMarkets }: { takerMarkets: TakerMarket[] }) => {
  const { t } = useTranslation();
  const { getRuleFormContent } = useRuleFormContent();
  const { getValues } = useRecurringRuleFormContext();
  const category = getValues('category');
  const { existingAlert } = getRuleFormContent(category);
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const existingRules = getRecurringRulesForTakerMarkets(takerMarkets);
  const existingRulesForCategory = existingRules.filter((rule) =>
    Object.keys(rule).includes(category === 'invoiceId' ? 'excludedVoucherIds' : category)
  );
  const hasExistingRulesForCategory = existingRulesForCategory.length > 0;

  return hasExistingRulesForCategory ? (
    <div>
      <Alert description={t(existingAlert)} full type="warning" variant="outlined" />
    </div>
  ) : null;
};

export default ExistingRulesAlert;
