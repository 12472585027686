import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '@c2fo/liquidity';
import apiClient from '@/lib/apiClient';
import { UpdateRecurringRuleArgument } from './useUpdateRecurringRule';

/**
 * hook for deleting a recurring rule and all its criteria
 */
export interface DeleteRecurringRuleArgument {
  id: UpdateRecurringRuleArgument['id'];
  makerOrganizationUuid: UpdateRecurringRuleArgument['makerOrganizationUuid'];
}

const useDeleteRecurringRule = () => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteRecurringRule'],
    mutationFn: (argument: DeleteRecurringRuleArgument) => {
      const { id, makerOrganizationUuid } = argument;

      return apiClient.delete(
        `api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`
      );
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid } = variables;
      queryClient.refetchQueries(['recurring-rules', makerOrganizationUuid]);

      showSnackbar({ message: t('recurringRules.deleteRuleSuccess') });
    },
  });
};

export default useDeleteRecurringRule;
