import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from '@c2fo/liquidity';
import useRefetchAndSubscribeForRules from '@/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import apiClient from '@/lib/apiClient';
import { RecurringRuleCategory } from './useRecurringRules';
import { UpdateRecurringRuleArgument, UpdateRecurringRuleMap } from './useUpdateRecurringRule';

/**
 * hook for deleting a single criteria type for a recurring rule
 * uses a PATCH request with null values to update the rule with the removed criteria
 */
export interface DeleteRecurringRuleCriteriaArgument
  extends Pick<
    UpdateRecurringRuleArgument,
    'makerOrganizationUuid' | 'marketId' | 'marketUuid' | 'takerId' | 'takerUuid'
  > {
  id: string;
  criteriaType: RecurringRuleCategory;
}

const useDeleteRecurringRuleCriteria = () => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['deleteRecurringRuleCriteria'],
    mutationFn: (rule: DeleteRecurringRuleCriteriaArgument) => {
      const { id, criteriaType, makerOrganizationUuid } = rule;

      /**
       * formats provided criteriaType to submit with null values to ensure any
       * removed criteria are updated correctly
       */
      let arg: UpdateRecurringRuleMap | null = null;

      if (criteriaType === 'dpe') {
        arg = {
          gteDpe: { value: null },
          lteDpe: { value: null },
        };
      }

      if (criteriaType === 'dueDate') {
        arg = {
          gtDueDate: { value: null },
          ltDueDate: { value: null },
        };
      }

      if (criteriaType === 'amount') {
        arg = {
          gteInvoiceAmount: { value: null },
          lteInvoiceAmount: { value: null },
        };
      }

      if (criteriaType === 'invoiceId') {
        arg = {
          excludedVoucherIds: { value: null },
        };
      }

      return apiClient
        .patch(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`, {
          json: arg,
        })
        .json();
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid, marketUuid, takerId } = variables;
      refetchAndSubscribeRules({ makerOrganizationUuid, takerMarkets: [{ marketUuid, takerId }] });

      showSnackbar({ message: t('recurringRules.deleteRuleSuccess') });
    },
  });
};

export default useDeleteRecurringRuleCriteria;
