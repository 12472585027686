import { useCallback } from 'react';
import { GetMeQuery, GetPreferredOfferConfigQuery } from '@/generated/gql/graphql';
import useAccountPreferredConfig from '@/data/useAccountPreferredConfig';
import { RecurringRule } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import useUserDetails from '@/data/useUserDetails';
import useGetRecurringRulesForTakerMarkets from '@/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';

export interface Restrictions {
  canEditInvoices: boolean;
  canEditOffers: boolean;
  canEditRecurringRules: boolean;
}

/**
 * default restrictions
 */
const defaultRestrictions: Restrictions = {
  canEditInvoices: true,
  canEditOffers: true,
  canEditRecurringRules: true,
};

// invoices can only be edited for a preferred offer if the account has the canEditInvoices flag set to true
const canEditInvoicesForPreferredOffer = (
  takerMarket: TakerMarket,
  accountPreferredConfigData?: GetPreferredOfferConfigQuery['getPreferredOfferConfig']
) => {
  const { isEnabled, offerType } = takerMarket.offerConfig;

  if (isEnabled && offerType === 'PREFERRED_TERM') {
    if (accountPreferredConfigData?.canEditInvoices) {
      return true;
    }

    return false;
  }

  return true;
};

// invoices can only be edited if the division does not have recurring rules
const canEditInvoicesWithRecurringRules = (
  takerMarkets: TakerMarket[],
  recurringRulesFn: (takerMarkets: TakerMarket[]) => RecurringRule[]
) => {
  return takerMarkets.length !== recurringRulesFn(takerMarkets).length;
};

/**
 * used only for displaying read only banner
 * for determining user restrictions, use the useRestrictions hook
 */
export const useReadOnlyUser = () => {
  const { data: readOnlyUser } = useUserDetails(useCallback((user: GetMeQuery) => user.me?.supplierReadOnly, []));
  return readOnlyUser ?? false;
};

const useRestrictions = () => {
  const readOnlyUser = useReadOnlyUser();
  const { data: accountPreferredConfigData } = useAccountPreferredConfig();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const getRestrictions = useCallback(
    (takerMarkets: TakerMarket[]) => {
      return {
        ...defaultRestrictions,
        canEditInvoices:
          !readOnlyUser &&
          takerMarkets.every((takerMarket) =>
            canEditInvoicesForPreferredOffer(takerMarket, accountPreferredConfigData)
          ) &&
          canEditInvoicesWithRecurringRules(takerMarkets, getRecurringRulesForTakerMarkets),
        canEditOffers: !readOnlyUser,
        canEditRecurringRules: !readOnlyUser,
      };
    },
    [accountPreferredConfigData, getRecurringRulesForTakerMarkets, readOnlyUser]
  );

  return { getRestrictions };
};

export default useRestrictions;
