import { ElementType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import { CalendarIcon, EllipsisVerticalIcon, MoneyBillWaveIcon, SquareQuoteIcon } from '@c2fo/liquidity/icons';
import useDeleteRecurringRule from '@/data/useDeleteRecurringRule';
import useDeleteRecurringRuleCriteria from '@/data/useDeleteRecurringRuleCriteria';
import { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import { RecurringRuleFormValues } from '../RecurringRulesForm';
import useRuleTitles from '../utils/getRulesTitles';
import { mapRecurringRuleToFormCriteria } from '../utils/utils';
import DeleteRuleModal from './DeleteRuleModal';

type RuleChipConfig = {
  [key in keyof Pick<RecurringRule, 'dpe' | 'dueDate' | 'amount' | 'excludedVoucherIds'>]: {
    label: string;
    type: RecurringRuleCategory;
    icon?: {
      icon: ElementType;
      name: string;
    };
    onEdit?: () => void;
    onDelete?: () => void;
  };
};

const RuleChip = ({
  label,
  icon,
  readOnly,
  type,
  onEdit,
  onDelete,
}: {
  label: string;
  type: RecurringRuleCategory;
  icon?: { icon: ElementType; name: string };
  readOnly: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();

  if (readOnly) {
    return <Chip label={label} type="info" variant="outlined" startIcon={icon} />;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger aria-label={t(`recurringRules.${type}.recurringRule`)}>
        <Chip
          label={label}
          type="info"
          variant="outlined"
          startIcon={icon}
          endIcon={{ icon: EllipsisVerticalIcon, name: t(`recurringRules.${type}.recurringRule`) }}
          useHoverStyles
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem key="edit" onClick={onEdit}>
          {t('recurringRules.editRule')}
        </DropdownMenuItem>
        <DropdownMenuItem key="delete" onClick={onDelete}>
          {t('recurringRules.deleteRule')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const RuleChips = ({
  recurringRule,
  readOnly = false,
  shortForm = false,
  onEdit,
}: {
  recurringRule: RecurringRule;
  readOnly?: boolean;
  shortForm?: boolean;
  onEdit?: (defaultValues: Partial<RecurringRuleFormValues>) => void;
}) => {
  const { asCurrency } = useLocaleFormat();
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [deleteRuleCategory, setDeleteRuleCategory] = useState<RecurringRuleCategory | null>(null);
  const { mutateAsync: deleteRecurringRule, isLoading: deleteRecurringRuleLoading } = useDeleteRecurringRule();
  const { mutateAsync: deleteRecurringRuleCriteria, isLoading: deleteRecurringRuleCriteriaLoading } =
    useDeleteRecurringRuleCriteria();
  const { getDpeRule, getInvoiceIdsRule, getInvoiceAmountRule, getDueDateRule } = useRuleTitles();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  const showReadOnly = readOnly || !canEditRecurringRules;

  const {
    dpe: dpeCriteria,
    dueDate: dueDateCriteria,
    amount: amountCriteria,
    invoiceId: invoiceIdCriteria,
  } = mapRecurringRuleToFormCriteria(recurringRule);

  const currency = recurringRule.currency;
  const gteDpe = recurringRule.dpe?.gteDpe;
  const lteDpe = recurringRule.dpe?.lteDpe;
  const gtDueDate = recurringRule.dueDate?.gtDueDate;
  const ltDueDate = recurringRule.dueDate?.ltDueDate;
  const gteInvoiceAmount = recurringRule.amount?.gteInvoiceAmount;
  const lteInvoiceAmount = recurringRule.amount?.lteInvoiceAmount;
  const excludedVoucherIds = recurringRule.excludedVoucherIds;

  const handleDeleteRule = async () => {
    /// when the rule has only one criteria, delete the whole rule
    if (recurringRule.count === 1) {
      await deleteRecurringRule({
        id: recurringRule.id,
        makerOrganizationUuid: recurringRule.makerOrganizationUuid,
      });
    } else {
      await deleteRecurringRuleCriteria({
        id: recurringRule.id,
        criteriaType: deleteRuleCategory!,
        makerOrganizationUuid: recurringRule.makerOrganizationUuid,
        marketId: recurringRule.marketId,
        marketUuid: recurringRule.marketUuid,
        takerId: recurringRule.takerId,
        takerUuid: recurringRule.takerUuid,
      });
    }

    setDeleteRuleCategory(null);
    setOpenDeleteRuleModal(false);
  };

  const handleOpenDeleteRuleModal = (ruleCategory: RecurringRuleCategory) => {
    setDeleteRuleCategory(ruleCategory);
    setOpenDeleteRuleModal(true);
  };

  const ruleChipConfig: RuleChipConfig = {
    dpe: {
      type: 'dpe',
      ...(shortForm
        ? {
            label: getDpeRule({ gteDpe, lteDpe })?.title ?? '',
          }
        : {
            label: getDpeRule({ gteDpe, lteDpe })?.description ?? '',
            icon: { icon: CalendarIcon, name: 'dpe rule' },
          }),
      ...(!showReadOnly && {
        onEdit: () => onEdit?.({ category: 'dpe', criteria: dpeCriteria }),
        onDelete: () => handleOpenDeleteRuleModal('dpe'),
      }),
    },
    dueDate: {
      type: 'dueDate',
      ...(shortForm
        ? {
            label: getDueDateRule({ gtDueDate, ltDueDate })?.title ?? '',
          }
        : {
            label: getDueDateRule({ gtDueDate, ltDueDate })?.description ?? '',
            icon: { icon: CalendarIcon, name: 'due date rule' },
          }),
      ...(!showReadOnly && {
        onEdit: () => onEdit?.({ category: 'dueDate', criteria: dueDateCriteria }),
        onDelete: () => handleOpenDeleteRuleModal('dueDate'),
      }),
    },
    amount: {
      type: 'amount',
      ...(shortForm
        ? {
            label:
              getInvoiceAmountRule({
                gteInvoiceAmount: gteInvoiceAmount ? asCurrency(gteInvoiceAmount, currency) : undefined,
                lteInvoiceAmount: lteInvoiceAmount ? asCurrency(lteInvoiceAmount, currency) : undefined,
              })?.title ?? '',
          }
        : {
            label:
              getInvoiceAmountRule({
                gteInvoiceAmount: gteInvoiceAmount ? asCurrency(gteInvoiceAmount, currency) : undefined,
                lteInvoiceAmount: lteInvoiceAmount ? asCurrency(lteInvoiceAmount, currency) : undefined,
              })?.description ?? '',
            icon: { icon: MoneyBillWaveIcon, name: 'invoice amount rule' },
          }),
      ...(!showReadOnly && {
        onEdit: () => onEdit?.({ category: 'amount', criteria: amountCriteria }),
        onDelete: () => handleOpenDeleteRuleModal('amount'),
      }),
    },
    excludedVoucherIds: {
      type: 'invoiceId',
      ...(shortForm
        ? { label: getInvoiceIdsRule(excludedVoucherIds)?.title ?? '' }
        : {
            label: getInvoiceIdsRule(excludedVoucherIds)?.description ?? '',
            icon: { icon: SquareQuoteIcon, name: 'invoice id rule' },
          }),
      ...(!showReadOnly && {
        onEdit: () => onEdit?.({ category: 'invoiceId', criteria: invoiceIdCriteria }),
        onDelete: () => handleOpenDeleteRuleModal('invoiceId'),
      }),
    },
  };

  return (
    <>
      <DeleteRuleModal
        loading={deleteRecurringRuleCriteriaLoading || deleteRecurringRuleLoading}
        onClose={() => {
          setOpenDeleteRuleModal(false);
        }}
        onDelete={handleDeleteRule}
        open={openDeleteRuleModal}
      />
      <div className="flex flex-wrap gap-2">
        {Object.entries(recurringRule)
          .flatMap(([key]) => key)
          .filter((key) => ['dpe', 'dueDate', 'amount', 'excludedVoucherIds'].includes(key))
          .map((key) => {
            const config = ruleChipConfig[key as keyof typeof ruleChipConfig];

            return config && config.label ? <RuleChip key={key} readOnly={showReadOnly} {...config} /> : null;
          })}
      </div>
    </>
  );
};

export default RuleChips;
