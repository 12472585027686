import { useTranslation } from 'react-i18next';
import { Button, Paper } from '@c2fo/liquidity';
import successmountain from '@/assets/successmountain.svg?url';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';

const NoActiveRules = ({ showRecurringRulesModal }: { showRecurringRulesModal: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);

  const onClick = () => {
    showRecurringRulesModal();
    track('recurring-rules::no-active-rules::create');
  };

  return (
    <Paper elevation={2}>
      <div className="m-auto flex max-w-[90rem] flex-col-reverse items-center gap-10 p-8 lg:flex-row lg:gap-20 lg:p-20">
        <div className="flex flex-col gap-6">
          <h3 className="m-0 text-2xl font-normal text-text-primary sm:text-3xl">
            {t('recurringRules.noActiveRulesTitle')}
          </h3>
          <p className="m-0 text-text-secondary">{t('recurringRules.noActiveRulesMessage')}</p>
          <p className="m-0 text-text-secondary">{t('recurringRules.noActiveRulesCtaMessage')}</p>
          <Button className="w-full sm:w-fit" onClick={onClick} variant="primary" disabled={!canEditRecurringRules}>
            {t('recurringRules.createRecurringRule')}
          </Button>
        </div>
        <div className="flex w-full justify-center self-center">
          <img src={successmountain} alt="mountain" className="max-w-64 lg:max-w-80" />
        </div>
      </div>
    </Paper>
  );
};

export default NoActiveRules;
