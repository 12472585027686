import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, IconButton, Paper, Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon, TrashCanIcon } from '@c2fo/liquidity/icons';
import EllipseTooltip from '@/components/EllipseTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import useDeleteRecurringRule, { DeleteRecurringRuleArgument } from '@/data/useDeleteRecurringRule';
import useDeleteRecurringRuleCriteria from '@/data/useDeleteRecurringRuleCriteria';
import useRecurringRules, { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import DeleteRuleModal from './components/DeleteRuleModal';
import Pagination, { getStartEnd } from './components/Pagination';
import RecurringRulesForm, { RecurringRulesFormProps } from './RecurringRulesForm';
import SetRuleCriteria from './SetRuleCriteria';
import sortRulesTable, { Sort, SortKey } from './utils/sortRulesTable';

const DivisionTitle = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const takerMarketName = getTakerMarketName(takerMarket);
  const buyerDivisionName = getTakerMarketDivisionTitle(takerMarket)?.content;
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  return (
    <div className="flex flex-col">
      <EllipseTooltip className="font-medium text-text-primary">{takerMarketName}</EllipseTooltip>
      <div className="text-sm text-text-primary">{takerMarket.currency}</div>
      <EllipseTooltip className="text-sm text-text-primary">{buyerDivisionName}</EllipseTooltip>
      {isPreferred && (
        <div className="flex gap-1">
          <Popover>
            <Popover.Trigger>
              <InfoCircleIcon className="h-4 w-4" fill={colors.gray[600]} />
            </Popover.Trigger>
            <Popover.Content arrow side="bottom">
              {t('recurringRules.preferredOfferLocked')}
            </Popover.Content>
          </Popover>
          <span className="text-wrap text-sm italic text-text-secondary">{t('core.preferredOffer')}</span>
        </div>
      )}
    </div>
  );
};

const RulesTable = () => {
  const { t } = useTranslation();
  const recurringRules = useRecurringRules();
  const { asCurrency } = useLocaleFormat();
  const ref = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { mutateAsync: deleteRecurringRule, isLoading: deleteRecurringRuleLoading } = useDeleteRecurringRule();
  const { mutateAsync: deleteRecurringRuleCriteria, isLoading: deleteRecurringRuleCriteriaLoading } =
    useDeleteRecurringRuleCriteria();
  const [ruleToDelete, setRuleToDelete] = useState<DeleteRecurringRuleArgument | null>(null);
  const [ruleCriteriaToDelete, setRuleCriteriaToDelete] = useState<{
    rule: RecurringRule;
    category: RecurringRuleCategory;
  } | null>(null);
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const sortedRules = sortRulesTable(recurringRules, sort);
  const totalCount = recurringRules.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const [ruleModalProps, setRuleModalProps] = useState<
    Pick<RecurringRulesFormProps, 'defaultValues' | 'mode' | 'takerMarkets' | 'type'>
  >({
    takerMarkets: [],
  });
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [deleteModalAllRules, setDeleteModalAllRules] = useState(false);
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);

  const [isScrollable, setIsScrollable] = useState(false);

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'takerMarketName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const findScrollable = () => {
    const scrollable = ref.current && ref.current.offsetWidth < ref.current.scrollWidth;
    setIsScrollable(!!scrollable);
  };

  useEffect(() => {
    findScrollable();
    window.addEventListener('resize', findScrollable);
  });

  const tableHeadColumns: { accessorKey?: SortKey; translation: string; className: string }[] = [
    {
      accessorKey: 'takerMarketName',
      translation: t('core.division'),
      className: cn('sticky left-0 top-0 z-[5] w-44 bg-white sm:w-60', { 'drop-shadow-lg': isScrollable }),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('taker.dictionary.availableAR.label'),
      className: 'w-36 pr-4',
    },
    {
      translation: t('core.daysPaidEarly'),
      className: 'w-48 text-center',
    },
    {
      translation: t('recurringRules.dueDate.category.label'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.invoiceAmount'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.invoiceId'),
      className: 'w-48 text-center',
    },
    {
      translation: '',
      className: 'w-20 text-center',
    },
  ];

  const handleOpenRuleModal = ({
    defaultValues,
    takerMarkets,
    type,
  }: Pick<RecurringRulesFormProps, 'defaultValues' | 'takerMarkets' | 'type'>) => {
    setRuleModalProps({
      defaultValues,
      takerMarkets,
      type,
      ...((defaultValues?.criteria?.length ?? 0) > 0 && { mode: 'edit' }),
    });
    setOpenRuleModal(true);
  };

  const handleOpenDeleteAllRulesModal = (rule: RecurringRule) => {
    setRuleToDelete({
      id: rule.id,
      makerOrganizationUuid: rule.makerOrganizationUuid,
    });
    setDeleteModalAllRules(true);
    setOpenDeleteRuleModal(true);
    setRuleCriteriaToDelete(null);
  };

  const handleOpenDeleteRuleModal = (rule: RecurringRule, category: RecurringRuleCategory) => {
    setOpenDeleteRuleModal(true);
    setRuleCriteriaToDelete({ rule, category });
  };

  const handleDeleteRule = async () => {
    if (deleteModalAllRules) {
      await deleteRecurringRule(ruleToDelete!);
    } else {
      if (ruleCriteriaToDelete) {
        /// when the rule has only one criteria, delete the whole rule
        if (ruleCriteriaToDelete.rule.count === 1) {
          await deleteRecurringRule({
            id: ruleCriteriaToDelete.rule.id,
            makerOrganizationUuid: ruleCriteriaToDelete.rule.makerOrganizationUuid,
          });
        } else {
          await deleteRecurringRuleCriteria({
            id: ruleCriteriaToDelete.rule.id,
            criteriaType: ruleCriteriaToDelete.category,
            makerOrganizationUuid: ruleCriteriaToDelete.rule.makerOrganizationUuid,
            marketId: ruleCriteriaToDelete.rule.marketId,
            marketUuid: ruleCriteriaToDelete.rule.marketUuid,
            takerId: ruleCriteriaToDelete.rule.takerId,
            takerUuid: ruleCriteriaToDelete.rule.takerUuid,
          });
        }
      }
    }

    setOpenDeleteRuleModal(false);
    setDeleteModalAllRules(false);
    setRuleToDelete(null);
    setRuleCriteriaToDelete(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteRuleModal(false);
    setDeleteModalAllRules(false);
    setRuleToDelete(null);
    setRuleCriteriaToDelete(null);
  };

  return (
    <div>
      <RecurringRulesForm
        isForSingleDivision
        onClose={() => setOpenRuleModal(false)}
        open={openRuleModal}
        {...ruleModalProps}
      />
      <DeleteRuleModal
        open={openDeleteRuleModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteRule}
        isAllRules={deleteModalAllRules}
        loading={deleteRecurringRuleLoading || deleteRecurringRuleCriteriaLoading}
      />
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.activeRules')}</h2>
      <Paper elevation={2}>
        <div className="w-full overflow-auto px-4 pt-4">
          <div ref={ref} className="overflow-auto rounded-md border-2 border-gray-200">
            <Table className="table-fixed">
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, translation, className }) => (
                    <TableHead
                      key={translation}
                      className={cn('px-2 py-4', className)}
                      {...(!!accessorKey && { onClick: () => onSort(accessorKey) })}
                    >
                      {!!accessorKey ? (
                        <TableSortArrow
                          accessorKey={accessorKey}
                          sort={sort}
                          {...(['eligibleInvoiceAmount'].includes(accessorKey) && { textRight: true })}
                        >
                          {translation}
                        </TableSortArrow>
                      ) : (
                        translation
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedRules.slice(start - 1, end).map((takerMarketWithRule) => {
                  const { rule, takerMarket } = takerMarketWithRule;
                  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

                  return (
                    <TableRow key={rule.id}>
                      <TableCell className={cn('sticky left-0 z-[5] bg-white', { 'drop-shadow-lg': isScrollable })}>
                        <DivisionTitle takerMarket={takerMarket} />
                      </TableCell>
                      <TableCell className="truncate border-r pr-4 text-end">
                        {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="dpe"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'dpe')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="dueDate"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'dueDate')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="amount"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'amount')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="invoiceId"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'invoiceId')}
                        />
                      </TableCell>
                      <TableCell className="px-0 last-of-type:pr-0">
                        <div className="flex items-center justify-center">
                          <IconButton
                            name={t('recurringRules.removeRule')}
                            variant="destructive"
                            size="md"
                            inverse
                            icon={TrashCanIcon}
                            onClick={() => handleOpenDeleteAllRulesModal(rule)}
                            disabled={isPreferred || !canEditRecurringRules}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="flex justify-center p-4">
          <Pagination
            limit={limit}
            onPageChange={setPage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            totalCount={totalCount}
          />
        </div>
      </Paper>
    </div>
  );
};

export default RulesTable;
