import { useQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';

export type SEAUserSupplierResponse =
  paths['/user/suppliers']['get']['responses']['200']['content']['application/json'];

const useSEAUserSuppliers = () => {
  const [enableSEApi] = useFeature(Features['enterprise-ui_enableSupplierExperienceApiPriceDiscoveryOfferReads']);

  return useQuery({
    queryKey: ['sea-user-suppliers'],
    queryFn: () => apiClient.get('api/supplier-experience/user/suppliers').json<SEAUserSupplierResponse>(),
    enabled: enableSEApi,
  });
};

export default useSEAUserSuppliers;
