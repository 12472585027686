import { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import { RecurringRuleFormValues } from '../RecurringRulesForm';
import { mapFormCriteriaToRuleRecord, RuleRecord } from './utils';

interface RuleByMakerOrg {
  [makerOrganizationUuid: string]: {
    [currency: string]: {
      takerMarkets: {
        marketId: number;
        marketUuid: string;
        offerConfig: OfferConfig;
        takerId: number;
        takerUuid: string;
      }[];
    } & Partial<RuleRecord>;
  };
}

/**
 * utility function for grouping rules data submitted via recurring rules form by maker organization
 */
const groupRulesByMakerOrg = (data: RecurringRuleFormValues): RuleByMakerOrg =>
  data.takerMarkets.reduce(
    (
      rulesByMakerOrg: RuleByMakerOrg,
      {
        currency,
        makerOrganizationUuid,
        legacyMarketId,
        marketUuid,
        offerConfig,
        takerDivisionId,
        takerDivisionUuid,
      }: TakerMarket
    ) => {
      const takerMarketMeta = {
        marketId: legacyMarketId,
        marketUuid,
        offerConfig,
        takerId: takerDivisionId,
        takerUuid: takerDivisionUuid,
      };

      // map criteria from form values to rule format for backend
      const ruleMeta = mapFormCriteriaToRuleRecord(data.criteria);

      const makerOrganizationUuidFound = Object.keys(rulesByMakerOrg).includes(makerOrganizationUuid);

      if (makerOrganizationUuidFound) {
        if (rulesByMakerOrg[makerOrganizationUuid][currency]) {
          rulesByMakerOrg[makerOrganizationUuid][currency].takerMarkets.push(takerMarketMeta);
        } else {
          rulesByMakerOrg[makerOrganizationUuid][currency] = {
            takerMarkets: [takerMarketMeta],
            ...ruleMeta,
          };
        }
      } else {
        rulesByMakerOrg[makerOrganizationUuid] = {
          [currency]: {
            takerMarkets: [takerMarketMeta],
            ...ruleMeta,
          },
        };
      }

      return rulesByMakerOrg;
    },
    {}
  );

export default groupRulesByMakerOrg;
