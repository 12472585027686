import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import useEditInvoices from '@/data/useEditInvoices';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import { useReporting } from '@/reporting';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';

export interface ExcludedInvoicesAlertProps {
  excludedAmount: number;
  takerMarkets: TakerMarket[];
  currency?: string;
  tab?: TakerMarketGroupType;
}

const ExcludedInvoicesAlert = ({ currency, excludedAmount, tab, takerMarkets }: ExcludedInvoicesAlertProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const { includeInvoices } = useEditInvoices();
  const selectedCurrency = useSelectedCurrency();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices } = getRestrictions(takerMarkets);

  if (takerMarkets.length === 0 || excludedAmount === 0) {
    return null;
  }

  const handleIncludeInvoices = async () => {
    setLoading(true);

    await includeInvoices({ filters: { all: true }, takerMarkets });

    track('invoice-include-banner::clicked', {
      tab: tab ?? 'division', // reinclude came from a homepage tab or division page
      takerMarkets: takerMarkets.map((tm) => ({
        takerId: tm.takerDivisionId,
        marketUuid: tm.marketUuid,
      })),
    });

    setLoading(false);
  };

  return (
    <Alert
      description={t('taker.reincludeInvoicesMessage', {
        amount: asCurrency(excludedAmount, currency ?? selectedCurrency),
      })}
      full
      type="warning"
      variant="outlined"
      {...(canEditInvoices && {
        action: {
          loading,
          onClick: handleIncludeInvoices,
          text: t('core.include'),
        },
      })}
    />
  );
};

export default ExcludedInvoicesAlert;
