import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuTrigger, IconButton } from '@c2fo/liquidity';
import { EllipsisVerticalIcon } from '@c2fo/liquidity/icons';
import ExcludeInvoicesModal from '@/components/ExcludeInvoicesModal';
import { TakerMarket } from '@/data/useTakerMarkets';
import RecurringRulesForm from '@/features/recurringRules/RecurringRulesForm';
import {
  ExcludeInvoicesMenuItem,
  RecurringRulesMenuItem,
} from '@/features/takerMarketTabs/components/TabActionsDropdown';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import getMarketType from '@/utils/getMarketType';
import useRestrictions from '@/utils/useRestrictions';

const EligibleInvoiceActionsDropdown = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const [showRecurringRulesModal, setShowRecurringRulesModal] = useState<boolean>(false);
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices, canEditRecurringRules } = getRestrictions([takerMarket]);
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');
  const [showExcludeInvoicesModal, setShowExcludeInvoicesModal] = useState<boolean>(false);
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  const openExcludeInvoicesModal = () => {
    setShowExcludeInvoicesModal(true);
    track('exclude-invoices::clicked', {
      takerMarket: { takerId: takerMarket.takerDivisionId, marketUuid: takerMarket.marketUuid },
      type: 'division',
    });
  };

  const openEligibleInvoiceActionsDropdown = () => {
    track('eligible-invoice-actions::clicked', {
      takerMarket: { takerId: takerMarket.takerDivisionId, marketUuid: takerMarket.marketUuid },
      type: 'division',
    });
  };

  const openRecurringRulesModal = () => {
    setShowRecurringRulesModal(true);
    track('recurring-rules::clicked', { type: 'division' });
  };

  return (
    <IntercomDataTarget target="eligibleInvoiceActionsDropdown">
      <RecurringRulesForm
        defaultValues={{ takerMarkets: [takerMarket] }}
        isForSingleDivision
        onClose={() => setShowRecurringRulesModal(false)}
        open={showRecurringRulesModal}
        takerMarkets={[takerMarket]}
      />
      <ExcludeInvoicesModal
        open={showExcludeInvoicesModal}
        onClose={() => setShowExcludeInvoicesModal(false)}
        takerMarkets={[takerMarket]}
        type="division"
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild onClick={openEligibleInvoiceActionsDropdown}>
          <IconButton icon={EllipsisVerticalIcon} name={t('taker.invoices.eligible.actionDropdown')} variant="cta" />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          avoidCollisions={false}
          className="min-w-64"
          collisionPadding={24}
          data-intercom-target="eligibleInvoiceActionsDropdownMenu"
        >
          <DropdownMenuLabel>{t('core.invoiceActions')}</DropdownMenuLabel>
          {enableRecurringRules && (
            <RecurringRulesMenuItem
              onClick={openRecurringRulesModal}
              readOnly={!canEditRecurringRules || isPreferred}
            />
          )}
          <ExcludeInvoicesMenuItem onClick={openExcludeInvoicesModal} readOnly={!canEditInvoices} />
        </DropdownMenuContent>
      </DropdownMenu>
    </IntercomDataTarget>
  );
};

export default EligibleInvoiceActionsDropdown;
