import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';

interface DeleteRuleModalProps {
  loading: boolean;
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  isAllRules?: boolean;
}

const DeleteRuleModal = ({ loading, onClose, onDelete, open, isAllRules = false }: DeleteRuleModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInverse>
        {isAllRules ? t('recurringRules.deleteRules') : t('recurringRules.deleteRule')}
      </ModalTitleInverse>
      <ModalContent>
        <span className="flex flex-col gap-3">
          <span>{isAllRules ? t('recurringRules.deleteAllRulesWarning') : t('recurringRules.deleteRuleWarning')}</span>
          <span>{t('recurringRules.deleteDescription')}</span>
          <span>{isAllRules ? t('recurringRules.deleteAllConfirmation') : t('recurringRules.deleteConfirmation')}</span>
        </span>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={onClose}>
          {t('core.cancel')}
        </Button>
        <Button variant="destructive" onClick={onDelete} loading={loading}>
          {isAllRules ? t('recurringRules.deleteRules') : t('recurringRules.deleteRule')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteRuleModal;
